import { createSlice } from '@reduxjs/toolkit';
import { fetchDocumentData } from '../thunks/documentData';
import DocumentDataApiSlice from '../../services/documentData/documentDataApi';

const initialDocumentState = {
  availableTemplates: [],
  documentData: null,
  documentMetaData: {},
  documentStatus: '',
  deliveryAttorneyEmail: '',
  deliveryCarrierEmail: '',
  deliveryCarrierFax: '',
  commonCarrierName: '',
  noPermissions: false,
  documentDataLoading: {},
  documentFilesLoading: false,
  shouldDeleteFile: false,
  batches: [],
  isAiProcessingForCreateDocument: false
};

const documentSlice = createSlice({
  name: 'Document',
  initialState: initialDocumentState,
  reducers: {
    setDocumentData: (state, action) => {
      state.documentData = action.payload;
    },
    setDocumentMetaData: (state, action) => {
      state.documentMetaData = action.payload;
    },
    setDocumentStatus: (state, action) => {
      state.documentStatus = action.payload;
    },
    setDeliveryAttorneyEmail: (state, action) => {
      state.deliveryAttorneyEmail = action.payload;
    },
    setCommonCarrierName: (state, action) => {
      state.commonCarrierName = action.payload;
    },
    setCarrierEmailAddress: (state, action) => {
      state.carrierEmailAddress = action.payload;
    },
    setDeliveryCarrierFax: (state, action) => {
      state.deliveryCarrierFax = action.payload;
    },
    setNoPermissions: (state, action) => {
      state.noPermissions = action.payload;
    },
    setDocumentDataLoading: (state, action) => {
      state.documentDataLoading = { ...state.documentDataLoading, [action.payload]: true };
    },
    clearDocumentState: () => initialDocumentState,
    setDocumentFilesLoading: (state, action) => {
      state.documentFilesLoading = action.payload;
    },
    setShouldDeleteFile: (state, action) => {
      state.shouldDeleteFile = action.payload;
    },
    setBatches: (state, action) => {
      state.batches = action.payload;
    },
    setAvailableTemplates: (state, action) => {
      state.availableTemplates = action.payload;
    },
    setIsAiProcessingForCreateDocument: (state, action) => {
      state.isAiProcessingForCreateDocument = action.payload;
    }
  },
  extraReducers: (builder) => {

    builder
      .addMatcher(
        (action) =>
          fetchDocumentData.fulfilled.match(action) || DocumentDataApiSlice.endpoints.getDocumentData.matchFulfilled(action),
        (state, action) => {

          if (action.payload) {
            state.documentData = action.payload.document;
            state.documentMetaData = action.payload.metadata;
            state.documentStatus = action.payload.metadata.documentStatus;
            state.deliveryAttorneyEmail = action.payload.deliveryAttorneyEmail;
            state.deliveryEmail = action.payload.deliveryEmail;
            state.deliveryFax = action.payload.deliveryFax;
            state.carrierEmailAddress = action.payload.deliveryEmail;
            state.carrierFaxNumber = action.payload.deliveryFax;
            state.noPermissions = false;
          } else {
            state.noPermissions = true;
          }
          state.documentDataLoading = { ...state.documentDataLoading, [action.meta.arg.documentId]: false };
        }
      )
      .addMatcher(
        (action) =>
          fetchDocumentData.pending.match(action) || DocumentDataApiSlice.endpoints.getDocumentData.matchPending(action),
        (state, action) => {
          state.documentDataLoading = { ...state.documentDataLoading, [action.meta.arg.documentId]: true };
        }
      )
      .addMatcher(
        (action) =>
          fetchDocumentData.rejected.match(action) || DocumentDataApiSlice.endpoints.getDocumentData.matchRejected(action),
        (state, action) => {
          state.documentDataLoading = { ...state.documentDataLoading, [action.meta.arg.documentId]: false };
        }
      )
  },
});

export const {
  setDocumentData,
  setDocumentMetaData,
  setDocumentStatus,
  setDeliveryEmail,
  setDeliveryFax,
  setCarrierEmailAddress,
  setCarrierFaxNumber,
  setNoPermissions,
  setDocumentDataLoading,
  clearDocumentState,
  setDocumentFilesLoading,
  setShouldDeleteFile,
  setBatches,
  setAvailableTemplates,
  setIsAiProcessingForCreateDocument
} = documentSlice.actions;

export default documentSlice.reducer;